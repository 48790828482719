import {defineStore} from 'pinia'
const navigationVisibilities = {
    isVisibilitySet: false,
    showCreditLink: false,
    showFacilitatorGuideLink: false,
    showExhibitorsLink: false,
    showMaterialsLink: false,
    showMyScheduleLink: false,
    showPartnersLink: false,
    showPresentersLink: false,
    showScheduleLink: false,
    showVenuesLink: false,
}

export const useApiStore = defineStore('api', {
    state: () => ({
        baseUrl: process.env.VUE_APP_API_ENDPOINT,
        ceCreditTypes: null,
        currentAttendee: null,
        currentEvent: null,
        currentPresenter: null,
        dateRange: null,
        displayName: '',
        events: null,
        facilitatorGuide: false,
        filterCeCreditType: '',
        filterText: '',
        filterTopic: '',
        filterType: '',
        filterVenue: '',
        isAdmin: false,
        isNewInstance: true,
        isPaidForCurrentEvent: false,
        isRegisteredForCurrentEvent: false,
        isStaff: false,
        leas: null,
        mySchedule: null,
        navigationVisibilities: navigationVisibilities,
        oid: null,
        pastEvents: null,
        paymentData: null,
        presenters: null,
        topics: null,
        topPastEvents: null,
    }),
    getters: {
        buildUrl: (state) => {
            return (path) => state.baseUrl + path
        },
    },
    actions: {
        removeSessionAsset(sessionId, materialId) {
            this.mySchedule.sessions.forEach((session) => {
                if (session.id === sessionId) {
                    session.assets = session.assets.filter((asset) => {
                        return asset.id !== materialId;
                    })
                }
            })
            // add assets to session passed to sessionItemList in MyScheduleView
            this.currentEvent.sessions.forEach((session) => {
                if (session.id === sessionId) {
                    session.assets = session.assets.filter((asset) => {
                        return asset.id !== materialId;
                    })
                }
            })
        },
        addSessionAsset(sessionId, assets) {
            const addFiles = (session) => {
                if (session.id === sessionId) {
                    if (Array.isArray(assets)) {
                        // MaterialVue for example sends an array of materials i.e. assets to add.
                        session.assets = session.assets.concat(assets)
                    } else {
                        // if it's not an array (shrug) this is my best guess what to do with it.
                        session.assets.push(assets)
                    }
                }
            }
            // add assets to session passed to sessionItemList in MyScheduleView
            this.mySchedule.sessions.forEach(addFiles)
            // add assets to session passed to sessionItemList in MyScheduleView
            this.currentEvent.sessions.forEach(addFiles)
        }
    }
})
