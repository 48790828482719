import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './router'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar'
import 'v-calendar/style.css'

const pinia = createPinia()
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
})
const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(setupCalendar, {})

app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)

app.mount('#app')
