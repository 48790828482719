<script>
import {useModal} from "@/store/modal";
export default {
  name: 'PattanModal',
  data() {
    return {
      modal: useModal(),
    }
  },
  methods: {
    closeModal() {
      this.modal.close()
    }
  },
}
</script>

<template>
  <teleport to="body">
    <div v-if="modal.isOpen">
      <div id="pattan-modal-wrapper" ref="target">
        <div class="modal pattan-modal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ modal.header }}</h5>
               </div>
              <div class="modal-body">
                <slot name="content"> {{ modal.content }}</slot>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closeModal()" aria-label="Close" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped>

.pattan-modal {
  display: grid;
  align-content: center;
  justify-content: center;
}

#pattan-modal-wrapper {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>