<template>
    <div v-if="false == production" class="non-production-marker">{{ hostName }}</div>
</template>

<script>
export default {
    name: 'NonProductionMarker',

    created() {
        this.hostName = location.hostname
        if ('events.pattan.net' != this.hostName) {
            this.production = false
        }
    },

    data() {
        return {
            hostName: 'events.pattan.net',
            production: true,
        }
    },
}
</script>

<style scoped>
.non-production-marker {
    background-color: var(--pattan-error-border);
    color: white;
    font-weight: bold;
    padding: 1rem;
}
</style>