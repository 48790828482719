
import { defineStore } from "pinia";

export const useModal = defineStore("modal", {
  state: () => ({
    isOpen: false,
    content: null,
    actions: [],
    header: null,
  }),
  actions: {
    open(content, header='Error', actions=[]) {
      this.isOpen = true;
      this.actions = actions;
      // using markRaw to avoid over performance as reactive is not required
      this.content = content;
      this.header = header;
    },
    close() {
      this.isOpen = false;
      this.content = null;
      this.actions = [];
      this.header = null;
    },
  },
});

export default useModal;