<template>
    <!-- This is the beginning of the left vertical nav -->
    <nav class="navbar navbar-vertical navbar-expand-lg">
        <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
            <!-- scrollbar removed-->
            <div class="navbar-vertical-content">
                <ul class="navbar-nav flex-column" id="navbarVerticalNav">
                    <li class="nav-item">

                        <!-- Home link -->  
                        <div class="nav-item-wrapper">
                            <a @click.prevent="verticalNavOptionClickHandler('home')" class="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                <div class="d-flex align-items-center"><span class="nav-link-icon" aria-label="Home"><span data-feather="home"></span></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Home</span></span>
                                </div>
                            </a>
                        </div>

                        <!-- Archive link -->  
                        <div class="nav-item-wrapper">
                            <a @click.prevent="verticalNavOptionClickHandler('archiveview')" class="nav-link label-1" href="#" role="button" data-bs-toggle="" aria-expanded="false">
                                <div class="d-flex align-items-center"><span class="nav-link-icon" aria-label="Archive"><span data-feather="archive"></span></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Event archive</span></span>
                                </div>
                            </a>
                        </div>

                        <!-- Event sub-menu -->
                        <div class="nav-item-wrapper" v-if="showEventSubMenu">
                            <a class="nav-link dropdown-indicator label-1" href="#event" role="button" data-bs-toggle="collapse"
                                aria-expanded="false" aria-controls="event">
                                <div class="d-flex align-items-center">
                                    <div class="dropdown-indicator-icon"><span class="fas fa-caret-right"></span></div>
                                    <span class="nav-link-icon" :aria-label="eventSubmenuText"><span class="far fa-bookmark"></span></span>
                                    <span class="nav-link-text">{{ eventSubmenuText }}</span>
                                </div>
                            </a>
                            <div class="parent-wrapper label-1">
                                <ul class="nav collapse parent" data-bs-parent="#navbarVerticalCollapse" id="event">
                                    <li class="collapsed-nav-item-title d-none">{{ eventSubmenuText }}</li>
                            
                                    <!-- Welcome -->
                                    <li class="nav-item">
                                        <a @click.prevent="verticalNavOptionClickHandler('welcomeview', apiStore.currentEvent.event.id)"
                                            class="nav-link" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-text">Welcome</span></div>
                                        </a>
                                    </li>
                            
                                    <!-- Event schedule -->
                                    <li class="nav-item" v-if="apiStore.navigationVisibilities.showScheduleLink">
                                        <a @click.prevent="verticalNavOptionClickHandler('scheduleview', apiStore.currentEvent.event.id)"
                                            class="nav-link" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-text">Full event schedule</span></div>
                                        </a>
                                    </li>

                                    <!-- My schedule -->
                                    <li class="nav-item" v-if="apiStore.navigationVisibilities.showMyScheduleLink">
                                        <a @click.prevent="verticalNavOptionClickHandler('myscheduleview', apiStore.currentEvent.event.id)"
                                            class="nav-link" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-text">My schedule</span></div>
                                        </a>
                                    </li>

                                    <!-- Presenters -->
                                    <li class="nav-item" v-if="apiStore.navigationVisibilities.showPresentersLink">
                                        <a @click.prevent="verticalNavOptionClickHandler('presentersview', apiStore.currentEvent.event.id)"
                                            class="nav-link" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-text">Presenters</span></div>
                                        </a>
                                    </li>

                                    <!-- Materials -->
                                    <li class="nav-item" v-if="apiStore.navigationVisibilities.showMaterialsLink">
                                        <a @click.prevent="verticalNavOptionClickHandler('eventmaterialsview', apiStore.currentEvent.event.id)"
                                            class="nav-link" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-text">Materials</span></div>
                                        </a>
                                    </li>

                                    <!-- Credit -->
                                    <li class="nav-item" v-if="apiStore.navigationVisibilities.showCreditLink">
                                        <a @click.prevent="verticalNavOptionClickHandler('creditview', apiStore.currentEvent.event.id)"
                                            class="nav-link" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-text">Event credit</span></div>
                                        </a>
                                    </li>

                                    <!--  Facilitator Guide  -->
                                    <li class="nav-item" v-if="apiStore.navigationVisibilities.showFacilitatorGuideLink">
                                        <a @click.prevent="verticalNavOptionClickHandler('facilitatorguideview', apiStore.currentEvent.event.id)"
                                            class="nav-link" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-text">Facilitator Guide</span></div>
                                        </a>
                                    </li>

                                    <!-- Venues -->
                                    <li class="nav-item" v-if="apiStore.navigationVisibilities.showVenuesLink">
                                        <a @click.prevent="verticalNavOptionClickHandler('eventvenuesview', apiStore.currentEvent.event.id)"
                                            class="nav-link" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-text">Venues</span></div>
                                        </a>
                                    </li>

                                    <!-- Partners -->
                                    <li class="nav-item" v-if="apiStore.navigationVisibilities.showPartnersLink">
                                        <a @click.prevent="verticalNavOptionClickHandler('eventpartnersview', apiStore.currentEvent.event.id)"
                                            class="nav-link" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-text">Partners</span></div>
                                        </a>
                                    </li>

                                    <!-- Exhibitors -->
                                    <li class="nav-item" v-if="apiStore.navigationVisibilities.showExhibitorsLink">
                                        <a @click.prevent="verticalNavOptionClickHandler('eventexhibitorsview', apiStore.currentEvent.event.id)"
                                            class="nav-link" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-text">Exhibitors</span></div>
                                        </a>
                                    </li>

                                </ul>
                            </div>

                        </div>
                        <!-- End event submenu -->

                    </li>
                </ul>
            </div>
        </div>

        <div class="navbar-vertical-footer">
            <button class="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center" value="Collapsed view" :aria-label="verticalNavCollapseAriaLabel"><span class="uil uil-left-arrow-to-left fs-0"><span class="no-text">Expanded View</span></span><span class="uil uil-arrow-from-right fs-0"></span><span class="navbar-vertical-footer-text ms-2">Collapsed View</span></button>
        </div>
    </nav>
    <!-- This is the end of the left vertical nav -->

    <!-- Beginning of top nav -->
    <nav class="navbar navbar-top navbar-expand screen-only" id="navbarDefault">
        <div class="collapse navbar-collapse justify-content-between">
            <div class="navbar-logo">

                <!-- Hamburger -->
                <button ref="humbutton" @click="humbuttonClickHandler" class="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>
            
                <!-- Logo -->
                <a class="navbar-brand me-1 me-sm-3" aria-label="PaTTAN Home Page" href="https://www.pattan.net/">
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center"><img src="/assets/img/icons/logo.svg" alt="PaTTAN" class="logo" />
                        </div>
                    </div>
                </a>

            </div>
        
            <div class="pb-2"><NonProductionMarker /></div>

            <ul class="navbar-nav navbar-nav-icons flex-row">

                <!-- Account drop-down -->
                <li class="nav-item dropdown">
                    <a v-if="showUserPhoto" class="nav-link lh-1 pe-0" id="navbarDropdownUser" href="#!" role="button"
                        data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false" aria-label="User Menu">
                        <img alt="AD photo" :src="getUserPhotoUri" height="48" width="48" style="border-radius: 50%"/>
                        <vue-feather type="chevron-down" />
                    </a>
                    <a v-else class="btn btn-phoenix-secondary d-flex flex-center w-100 loginLink" href="#">
                        <span class="me-2" data-feather="log-in"></span>
                        <span class="loginLinkText"></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300" aria-labelledby="navbarDropdownUser">
                        <div class="card position-relative border-0">
                            <div class="card-body p-0">
                                <div class="text-center pt-4 pb-3">
                                    <span data-feather="user" style="height: 24px; width: 24px"></span>
                                    <h6 class="mt-2 text-black"><span id="accountNameText"></span></h6>
                                    <h6 class="mt-2 text-black"><span id="accountEmailText"></span></h6>
                                </div>
                            </div>
                            <div class="card-footer border-top" id="accountLinks">
                                <div class="px-3">
                                    <div class="nav-item-wrapper">
                                        <a @click.prevent="verticalNavOptionClickHandler('myprofileview')" class="nav-link label-1" href="#"
                                            role="button" data-bs-toggle="" aria-expanded="false">
                                            <div class="d-flex align-items-center"><span class="nav-link-icon">
                                                <span data-feather="user"></span></span>
                                                <span class="nav-link-text-wrapper"><span class="nav-link-text">&nbsp;My profile</span></span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer border-top">
                                <div class="px-3">
                                    <a class="btn btn-phoenix-secondary d-flex flex-center w-100 loginLink" href="#">
                                        <span class="me-2" data-feather="log-out"></span>
                                        <span class="loginLinkText">Sign out</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
    
            </ul>
        </div>
    </nav>
    <!-- End of top nav -->

    <div class="content">

        <div class="content-min-h">
            <router-view /> <!-- <<<<< Here's where the views appear -->
            <PattanModal></PattanModal>
        </div>
          
        <!-- Footer styling is needed to maintain content styling -->
        <footer class="footer position-absolute">
            <div class="row g-0 justify-content-between align-items-center h-100">
                <div class="col-12 col-sm-auto text-center">
                    <p class="mb-0 mt-2 mt-sm-0 text-900"><span class="d-none d-sm-inline-block"></span><span class="d-none d-sm-inline-block mx-1"></span><br class="d-sm-none" /><span class="footer-text">&copy; 2023 PaTTAN; &copy; 2023 Themewagon</span><a class="mx-1"></a></p>
                </div>
            </div>
        </footer>

    </div>
</template>

<script>
import {appGlobals} from '@/shared/appGlobals'
import 'bootstrap'
import {useApiStore} from '@/store/api'
import NonProductionMarker from '@/components/NonProductionMarker'
import PattanModal from '@/components/PattanModal.vue'
import VueFeather from 'vue-feather'
export default {
    beforeCreate() {
        if ('dark' == localStorage.getItem('phoenixTheme')) {
            localStorage.setItem('phoenixTheme', 'light')
            window.location = '/'
        }
    },

    components: {
        NonProductionMarker,
        PattanModal,
        VueFeather,
    },

    computed: {
        eventSubmenuText() {
            return getCurrentEventShortName()
        },

        showEventSubMenu() {
            return null != this.apiStore.currentEvent
        },

        showUserPhoto() {
            return Boolean(this.apiStore.currentAttendee)
        },

        getUserPhotoUri(){
            if(this.apiStore.currentAttendee && this.apiStore.currentAttendee.photo) {
                return this.apiStore.currentAttendee.photo.uri
            }

            return appGlobals.genericProfilePhotoPath

        }
    },

    data() {
        return {
            apiStore: useApiStore(),
            isHumbuttonClicked: false,
            verticalNavCollapseAriaLabel: 'Collapse side navigation',
        }
    },

    methods: {
        humbuttonClickHandler() {
            this.isHumbuttonClicked = !this.isHumbuttonClicked
        },

        verticalNavOptionClickHandler(routeName, eventId = null) {
            const route = {name: routeName}
            if (null != eventId) {
                route.params = {eventId: eventId}
            }
            this.$router.push(route)
            if (this.isHumbuttonClicked) {
                this.$refs.humbutton.click()
            }
            document.getElementById('navbarDropdownUser').click()
        },
    },

    mounted() {
        document.title = 'PaTTAN - Events'
    },
}

// Defined and exported here because in this file you can't import a function that includes a reference to a Pinia store
export const getCurrentEventShortName = function() {
    const apiStore = useApiStore()
    
    if (apiStore.currentEvent) {
        if (apiStore.currentEvent.event.short_name) {
            return apiStore.currentEvent.event.short_name
        } else {
            let truncatedName = apiStore.currentEvent.event.name
            if (21 < truncatedName.length) {
                truncatedName = truncatedName.substring(0, 20) + '...'
            }

            apiStore.currentEvent.event.short_name = truncatedName
            return truncatedName
        }
    }

    return 'Selected event'
}
</script>

<style scoped>

a {
    cursor: pointer;
}

.footer-text {
    font-size: 0.8em;
}

.logo {
    width: 8rem;
    height: auto;
}

.nav-link {
    color: var(--phoenix-navbar-vertical-link-color);
}

@media only screen and (min-width: 400px) {
    .logo {
        width: 10rem;
        height: auto;
    }
}

</style>
